import axios from 'axios';
import { getToken } from '../services/localstorage';
import config from '../config';
import { authActionType as actionTypes } from '../constants';

// Add a request interceptor
const configureAxios = dispatch => {
  console.log('configureAxios');
  axios.interceptors.request.use(
    function (config) {
      // adding token to the authorization header only
      const token = getToken();
      if (token) {
        config.headers = {
          authorization: `Bearer ${token}`
        };
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      console.log(error.response);
      if (error.response && parseInt(error.response.status, 10) === 401) {
        if (
          error.response.config.url === `${config.config().baseUrl}/v1/user/login` ||
          error.response.config.url === `${config.config().baseUrl}/v1/user/logout`
        ) {
          return Promise.reject(error);
        }

        //if 401 , log the user out.
        dispatch({ type: actionTypes.LOGOUT });
        throw error;
      }
      if (error.response && parseInt(error.response.status, 10) === 500) {
        dispatch({ type: actionTypes.SERVER_ERROR });
      }

      if (error.response && parseInt(error.response.status, 10) === 404) {
        dispatch({ type: actionTypes.PAGE_NOT_FOUND });
      }

      //TODO:  handle 403 case too
      return Promise.reject(error);
    }
  );
};

export default configureAxios;
