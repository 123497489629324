import React, { createContext, useReducer, useContext } from 'react';
import { authActionType as actionType } from './constants';
import { getUserInfo, saveToken, saveUserInfo, removeItem } from './services/localstorage';

export const AuthStateContext = createContext();
const reducer = (state, action) => {
  switch (action.type) {
    case actionType.LOGIN_SUCCESS:
      saveToken(action.payload.sessionToken);
      saveUserInfo(action.payload.userInfo);
      return {
        ...state,
        isAuthenticated: true,
        userInfo: action.payload.userInfo
      };

    case actionType.LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        userInfo: undefined
      };

    case actionType.LOGOUT:
      removeItem();
      return {
        ...state,
        isAuthenticated: false,
        userInfo: undefined
      };
    case actionType.PAGE_NOT_FOUND:
      return {
        ...state,
        pageNotFound: true
      };
    case actionType.SERVER_ERROR:
      return {
        ...state,
        serverError: true
      };
    default:
      break;
  }
};
let authInfo = JSON.parse(getUserInfo());
if (!authInfo) {
  authInfo = {};
}

const initialState = {
  isAuthenticated: authInfo.isAuthenticated ? true : false,
  loading: false,
  userInfo: authInfo.userInfo ? authInfo.userInfo : undefined,
  pageNotFound: false,
  serverError: false
};
export const AuthProvider = ({ children }) => {
  return (
    <AuthStateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AuthStateContext.Provider>
  );
};
export const useAuth = () => useContext(AuthStateContext);
