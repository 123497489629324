import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`




.lds-roller {
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;
    bottom:0;
    right:0;
    div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 32px 32px;
        &:after {
            content: " ";
            display: block;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
            margin: -3px 0 0 -3px;
            opacity:0.8px;
        }
        &:nth-child(1) {
            animation-delay: -0.036s;
            &:after {
                top: 50px;
                left: 50px;
            }
        }
        &:nth-child(2) {
            animation-delay: -0.072s;
            &:after {
                top: 54px;
                left: 45px;
            }
        }
        &:nth-child(3) {
            animation-delay: -0.108s;
            &:after {
                top: 57px;
                left: 39px;
            }
        }
        &:nth-child(4) {
            animation-delay: -0.144s;
            &:after {
                top: 58px;
                left: 32px;
            }
        }
        &:nth-child(5) {
            animation-delay: -0.18s;
            &:after {
                top: 57px;
                left: 25px;
            }
        }
        &:nth-child(6) {
            animation-delay: -0.216s;
            &:after {
                top: 54px;
                left: 19px;
            }
        }
        &:nth-child(7) {
            animation-delay: -0.252s;
            &:after {
                top: 50px;
                left: 14px;
            }
        }
        &:nth-child(8) {
            animation-delay: -0.288s;
            &:after {
                top: 45px;
                left: 10px;
            }
        }
    }
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.templateModal {
    width:1100px;
    max-width: 100%;
    @media(max-width:1024px){
        width:100%;
    }
    .sticky {
        position: sticky;
        top: 30px;
    }
    .popup-action-buttons {
        z-index: 99;
        margin-top: -94px;
        margin-bottom: 65px;
        margin-right: 50px;
    }
    .modal-content{
        height: calc(100vh - 110px);
        @media(max-width:1024px){
            height: calc(100vh - 180px);
        }
        overflow:overlay;
        .staticButton{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -40px;
            right: 0;
            left: 0;
            .form-group{
                margin:0 10px;
            }
        }
    }
}
.CustomRadio{
    display:contents;
    input{
        opacity:0;
        & ~ label ~ span {
            width: 87px;
            line-height: 24px;
            color: #333333;
            font-size: 8px;
            display: inline-block;
            text-align: center;
            background: #fcfcfc;
            box-shadow: inset 0 0 1px 0 rgba(0,0,0,0.1);
            border-radius: 5px;
        }
        &:checked ~ label  ~ span{
            display: inline-block;
            border-radius: 5px;
            background: #333;
            font-weight:700;
            transform: translateX(0px);
            box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
            transition: all 150ms;
            vertical-align: top;
            position: relative;
            color:#fff;
        }
    }
    .crew{
        margin-right: 12px;
    }
}

.companyAssests{
    background: #e4e8ec;
    padding: 10px 0px;
    margin: 10px 0px;
}

`;
