export function getToken() {
  const token = localStorage.getItem('token');

  return token;
}
export function getUserInfo() {
  return localStorage.getItem('userInfo');
}
export function saveToken(token, notificationToken) {
  if (token) {
    localStorage.setItem('token', token);
  }
}
export function saveUserInfo(userInfo) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

export function removeItem() {
  localStorage.removeItem('token');
  localStorage.removeItem('userInfo');
}
