const LOCAL = 'http://localhost:5000/api';
const PROD = 'https://api-employment.springverify.com/api';
const DEV = 'https://api-dev-employment.springverify.com/api';
export default {
  config: () => {
    switch (process.env.REACT_APP_STAGE) {
      case 'localhost':
        return {
          baseUrl: LOCAL
        };
      case 'production':
        return {
          baseUrl: PROD
        };
      case 'development':
        return {
          baseUrl: DEV
        };

      default:
        return {
          baseUrl: LOCAL
        };
    }
  }
};
