import React, { useEffect, lazy, Suspense } from 'react';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './AuthProvider';
import configureAxios from './api/axiosUtility';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './css/global';
import Loader from './reusable-components/Loader';
const AuthenticatedApp = React.lazy(() => import('./components/AuthenticatedApp'));
const UnauthenticatedApp = lazy(() => import('./components/UnauthenticatedApp'));
function App() {
  const [{ isAuthenticated, serverError, pageNotFound }, dispatch] = useAuth();
  useEffect(() => {
    configureAxios(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (serverError) {
    return <h2>Internal Server Error</h2>;
  }
  if (pageNotFound) {
    return <h2>Page Not Found</h2>;
  }
  return (
    <>
      <GlobalStyle />
      <ToastContainer autoClose={false} />
      <Suspense fallback={<Loader />}>
        <div className='App'>{isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}</div>
      </Suspense>
    </>
  );
}

export default App;
